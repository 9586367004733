/*****************************
    Banner
*****************************/
.banner {
    .banner-sub-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: 20px;
        display: flex;
    }

    .slider-social {
        display: flex;
        position: absolute;
        z-index: 2;
        top: 50%;
        bottom: 0;
        right: 50px;
        padding-top: 40px;
        text-align: center;
        transform: translate(0px, -50%);

        .slider-social-info {
            ul {
                li {
                    a {
                        color: $white;
                        transform: rotate(-90deg);
                        display: inline-block;
                        padding: 35px 0px;
                        font-size: 20px;

                        &:hover {
                            color: $primary;
                        }

                    }

                }

            }

        }

        span {
            display: flex;
            margin-top: 30px;
            font-size: 16px;
            font-weight: 500;
            color: $white;
            transform: rotate(-90deg);
            text-transform: uppercase;
            align-items: center;

            &:after {
                content: "";
                background: $white;
                width: 30px;
                height: 3px;
                display: inline-flex;
                margin-left: 20px;
                margin-right: 40px;
                align-items: center;
                justify-content: center;
            }

        }

    }

    .swiper-slide {
        height: 950px;
        padding-top: 87px;

        .title {
            font-size: 134px;
            line-height: 1;
        }

        p {
            font-size: 20px;
            line-height: 28px;
            margin: 20px 0 20px;
            justify-content: space-between;
        }

    }

    .pagination-button {
        .swiper-button-prev {
            background-image: none;
            display: flex;
            color: $white;
            align-items: center;
            top: auto;
            right: 200px;
            bottom: 50px;
            left: auto;
            font-size: 20px;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $primary;
            }

            &:after {
                content: "";
                background: $white;
                width: 110px;
                height: 2px;
                left: 60px;
                position: absolute;
            }

        }

        .swiper-button-next {
            background-image: none;
            display: flex;
            color: $white;
            align-items: center;
            bottom: 50px;
            top: auto;
            left: auto;
            right: 10px;
            transition: all 0.3s ease-in-out;
            font-size: 20px;

            &:hover {
                color: $primary;
            }

        }

    }

}

/*Banner 02*/
.banner.banner-02 {
    .swiper-slide {
        padding-top: 0;

        .banner-bg-image {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
        }

        .banner-sub-title {
            position: absolute;
            left: -200px;
            margin-bottom: 0;
            top: 35px;
        }

        p {
            font-size: 22px;
            line-height: 34px;
        }

    }

    .pagination-button {
        .swiper-button-prev {
            left: -80px;
            right: auto;
        }

        .swiper-button-next {
            right: auto;
            left: 110px;
        }

    }

    .slider-social {
        top: 90%;
        bottom: 0;
        right: 0;
        left: 0;
        transform: none;
        padding-top: 10px;

        .slider-social-info {
            text-align: center;
            display: flex;
            justify-content: flex-end;

            ul {
                display: flex;

                li {
                    a {
                        transform: none;
                        padding: 0px 15px;
                    }

                }

            }

        }

    }

}

/*Banner 03*/
.banner.banner-03 {
    .swiper-slide {
        .banner-content {
            margin-bottom: 50px;
            margin-left: 50px;

            .banner-sub-title {
                font-size: 32px;
            }

            h1 {
                font-size: 130px;
                padding-bottom: 15px;

                &:after {
                    content: "";
                    position: absolute;
                    background: $white;
                    width: 13%;
                    height: 8px;
                    left: 0;
                    bottom: 0;
                }

            }

        }

        .banner-content-info {
            p {
                display: flex;
                width: 20%;
                position: absolute;
                top: 250px;
                right: 170px;
                color: $white;
            }

        }

    }

    .pagination-button {
        .swiper-button-prev {
            bottom: 100px;
        }

        .swiper-button-next {
            bottom: 100px;
        }

    }

}

@media (max-width:1800px) {
    .banner.banner-02 {
        .swiper-slide {
            .banner-sub-title {
                position: initial;
                margin-bottom: 20px;
            }

        }

        .pagination-button {
            .swiper-button-prev {
                left: -40px;
                right: auto;
            }

            .swiper-button-next {
                left: 150px;
            }

        }

    }

}

@media (max-width:1300px) {
    .banner.banner-02 {
        .pagination-button {
            .swiper-button-prev {
                left: 0;
                right: auto;

                &::after {
                    width: 70px;
                }

            }

            .swiper-button-next {
                left: 150px;
            }

        }

    }

    .banner.banner-03 {
        .swiper-slide {
            .banner-content {
                h1 {
                    font-size: 80px;
                }

            }

            .banner-content-info {
                p {
                    width: 30%;
                    right: 80px;
                }

            }

        }

    }

}

@media (max-width:991px) {
    .banner {
        .swiper-slide {
            height: 650px;
            padding-top: 0;
        }

        .slider-social {
            display: none;
        }

    }

    .banner.banner-03 {
        .swiper-slide {
            .banner-content {
                .banner-sub-title {
                    font-size: 22px;
                }

            }

            .banner-content-info {
                p {
                    width: 30%;
                    top: 170px;
                    right: 70px;
                }

            }

        }

    }

}

@media (max-width:767px) {
    .banner {
        .swiper-slide {
            height: 600px;
        }

    }

    .banner.banner-02 {
        .swiper-slide {
            height: 700px;

            p {
                margin-bottom: 15px;
                padding-right: 0 !important;
            }

        }

        .pagination-button {
            .swiper-button-prev {
                left: 15px;
            }

            .swiper-button-next {
                left: 165px;
            }

        }

    }

    .banner.banner-03 {
        .swiper-slide {
            .banner-content {
                margin-bottom: 100px;
                margin-left: 20px;

                .banner-sub-title {
                    font-size: 18px;
                    margin-bottom: 10px;
                }

                h1 {
                    font-size: 60px;

                    &:after {
                        height: 3px;
                    }

                }

            }

            .banner-content-info {
                p {
                    width: 50%;
                    top: 150px;
                    right: 20px;
                    font-size: 16px;
                    line-height: 24px;
                }

            }

        }

        .pagination-button {
            .swiper-button-prev {
                bottom: 20px;
            }

            .swiper-button-next {
                bottom: 20px;
            }

        }

    }

}

@media (max-width:575px) {
    .banner {
        .swiper-slide {
            height: 450px;
        }

        .pagination-button {
            .swiper-button-prev {
                right: 230px;
            }

            .swiper-button-next {
                right: 40px;
            }

        }

    }

    .banner.banner-03 {
        .swiper-slide {
            .banner-content {
                margin-left: 0;

                h1 {
                    font-size: 36px;
                }

            }

            .banner-content-info {
                p {
                    width: 80%;
                    right: 15px;
                    top: 100px;
                }

            }

        }

        .pagination-button {
            .swiper-button-prev {
                right: 250px;
            }

            .swiper-button-next {
                right: 60px;
            }

        }

    }

}
