/*****************************
  Feature Info
*****************************/
.feature-item {
	.feature-icon {
		padding-bottom: 15px;
		display: flex;
	}

	.feature-content {
		.feature-title {
			padding-bottom: 15px;
			margin-bottom: 0;
			font-weight: 600;
		}

		p {
			margin-bottom: 0;
		}

	}

}

/*feature style 01*/
.feature-item.feature-info-style-01 {
	padding: 40px 40px 60px;
	transition: all 0.3s ease-in-out;
	background-color: $gray-1;

	.feature-icon {
		padding-bottom: 0;

		object {
			width: 120px;
			filter: $filter-1;
			transition: all 0.3s ease-in-out;
		}

	}

	&:hover {
		background-color: $primary;
		box-shadow: 0px 5px 25px rgba($black, 0.1);

		.feature-icon {
			object {
				filter: $filter-2;
				webkit-filter: $filter-2;
				-webkit-filter: $filter-2;
				-moz-filter: $filter-2;
			}

		}

		.feature-content {
			h4 {
				color: $white;
			}

			p {
				color: $white;
			}

		}

	}

}

.feature-item.feature-info-style-01.active {
	background-color: #f95903;
	box-shadow: 0px 5px 25px rgba($black, 0.1);

	.feature-icon {
		object {
			filter: $filter-2;
		}

	}

	.feature-content {
		h4 {
			color: $white;
		}

		p {
			color: $white;
		}

	}

}

.feature-info-center {
	text-align: center;

	.feature-icon {
		justify-content: center;
	}

}

.feature-info-right {
	text-align: right;

	.feature-icon {
		justify-content: end;
	}

}

.feature-item.feature-info-style-01.feature-info-center {
	.feature-icon {
		display: inherit;
		justify-content: inherit;
	}

}

/*feature style 02*/
.feature-item.feature-info-style-02 {
	padding: 23px 40px;
	border: 1px solid $border-color;

	.feature-icon {
		object {
			width: 70px;
			filter: $filter-1;
			transition: all 0.3s ease-in-out;
		}

	}

}

.feature-item.feature-info-style-02.feature-info-center {
	.feature-icon {
		display: inherit;
		justify-content: inherit;
	}

}

/*feature style 03*/
.feature-item.feature-info-style-03 {
	margin-bottom: 30px;
	display: flex;

	.feature-icon {
		padding-bottom: 0;
		height: 100px;
		min-width: 100px;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-right: 30px;
		background-color: $primary;

		object {
			width: 40px;
			filter: $filter-2;
			transition: all 0.3s ease-in-out;
		}

	}

	.feature-content {
		h6.feature-title {
			color: $primary;
			padding-bottom: 7px;
		}

		span {
			display: block;
		}

	}

}

.workplaces-service-box {
	.feature-info-style-01 {
		background-color: transparent;
	}

}

/*feature style 04*/
.feature-item.feature-info-style-04 {
	display: flex;

	.feature-icon {
		height: 100px;
		width: 100px;
		flex: 0 0 100px;
		line-height: 100px;
		align-items: center;
		display: flex;
		justify-content: center;
		margin-right: 30px;
		padding-bottom: 0;
		background-color: $primary;

		object {
			width: 110px;
			filter: $filter-2;
			transition: all 0.3s ease-in-out;
		}

	}

	.feature-content {
		h4 {
			color: $white;
		}

		p {
			color: $white;
		}

	}

}

.feature-item.feature-info-style-04.feature-item-right {
	.feature-icon {
		margin-left: 30px;
	}

}

@media (max-width:1200px) {
	.feature-item.feature-info-style-01 {
		margin-bottom: 30px;
	}

}

@media (max-width:991px) {
	.feature-item.feature-info-style-02 {
		margin-bottom: 30px;
	}

	.electric-feature-section {
		.feature-items.feature-info-style-04 {
			margin-top: 0;
			margin-bottom: 30px;
		}

	}

	.feature-item.feature-info-style-04.feature-item-right {
		.feature-icon {
			margin-left: 0px;
		}

	}

}

@media (max-width:479px) {
	.feature-item.feature-info-style-04 {
		margin-bottom: 30px;
		display: block;
		text-align: center;

		.feature-icon {
			margin: 0 auto;
			height: 70px;
			width: 70px;

			object {
				width: 70px;
			}

		}

		.feature-content {
			padding-top: 20px;
		}

	}

	.feature-item.feature-info-style-04.feature-item-right {
		.feature-icon {
			margin: 0 auto;
		}

	}

}
